<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <form v-if="addNewRoom" @submit.prevent="createRoom">
      <input v-model="addRoomUsername" type="text" placeholder="Add username"/>
      <button type="submit" :disabled="disableForm || !addRoomUsername">
        Create Room
      </button>
      <button class="button-cancel" @click="addNewRoom = false">Cancel</button>
    </form>

    <form v-if="inviteRoomId" @submit.prevent="addRoomUser">
      <input v-model="invitedUsername" type="text" placeholder="Add username"/>
      <button type="submit" :disabled="disableForm || !invitedUsername">
        Add User
      </button>
      <button class="button-cancel" @click="inviteRoomId = null">Cancel</button>
    </form>

    <form v-if="removeRoomId" @submit.prevent="deleteRoomUser">
      <select v-model="removeUserId">
        <option default value="">Select User</option>
        <option v-for="user in removeUsers" :key="user._id" :value="user._id">
          {{ user.username }}
        </option>
      </select>
      <button type="submit" :disabled="disableForm || !removeUserId">
        Remove User
      </button>
      <button class="button-cancel" @click="removeRoomId = null">Cancel</button>
    </form>

    <vue-advanced-chat
        ref="chatWindow"
        :height="screenHeight"
        :theme="theme"
        :styles="JSON.stringify(styles)"
        :current-user-id="currentUserId"
        :room-id="roomId"
        :rooms="JSON.stringify(loadedRooms)"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :room-message="roomMessage"
        :room-actions="JSON.stringify(roomActions)"
        :menu-actions="JSON.stringify(menuActions)"
        :message-selection-actions="JSON.stringify(messageSelectionActions)"
        :templates-text="JSON.stringify(templatesText)"
        @fetch-more-rooms="fetchMoreRooms"
        @fetch-messages="fetchMessages($event.detail[0])"
        @send-message="sendMessage($event.detail[0])"
        @edit-message="editMessage($event.detail[0])"
        @delete-message="deleteMessage($event.detail[0])"
        @open-file="openFile($event.detail[0])"
        @open-user-tag="openUserTag($event.detail[0])"
        @add-room="addRoom($event.detail[0])"
        @room-action-handler="menuActionHandler($event.detail[0])"
        @menu-action-handler="menuActionHandler($event.detail[0])"
        @message-selection-action-handler="
				messageSelectionActionHandler($event.detail[0])
			"
        @send-message-reaction="sendMessageReaction($event.detail[0])"
        @typing-message="typingMessage($event.detail[0])"
        @toggle-rooms-list="$emit('show-demo-options', $event.detail[0].opened)"
    >
      <!-- <div
        v-for="message in messages"
        :slot="'message_' + message._id"
        :key="message._id"
      >
        New message container
      </div> -->
    </vue-advanced-chat>
  </div>
</template>

<script>
import {parseTimestamp, formatTimestamp} from './utils/dates'
// import logoAvatar from './assets/logo.png'

import {register} from 'vue-advanced-chat'

register()

export default {
  props: {
    theme: {type: String, required: true},
    isDevice: {type: Boolean, required: true}
  },

  emits: ['show-demo-options'],

  data() {
    return {
      token: localStorage.getItem('token'),
      socket: null, // 新增WebSocket实例
      currentUserId: null,
      roomsPerPage: 15,
      rooms: [],
      roomId: '',
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: 0,
      selectedRoom: null,
      messagesPerPage: 20,
      messages: [],
      messagesLoaded: false,
      roomMessage: '',
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      roomActions: [
        {name: 'inviteUser', title: 'Invite User'},
        {name: 'removeUser', title: 'Remove User'},
        {name: 'deleteRoom', title: 'Delete Room'}
      ],
      menuActions: [
        {name: 'inviteUser', title: 'Invite User'},
        {name: 'removeUser', title: 'Remove User'},
        {name: 'deleteRoom', title: 'Delete Room'}
      ],
      messageSelectionActions: [{name: 'deleteMessages', title: 'Delete'}],
      styles: {container: {borderRadius: '4px'}},
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help'
        },
        {
          tag: 'action',
          text: 'This is the action'
        },
        {
          tag: 'action 2',
          text: 'This is the second action'
        }
      ]
      // ,dbRequestCount: 0
    }
  },

  computed: {
    loadedRooms() {
      return this.rooms.slice(0, this.roomsLoadedCount)
    },
    screenHeight() {
      return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
    }
  },

  mounted() {
    // this.addCss()

    // 配置ws
    this.configWebSocket()

    this.fetchRooms()
  },

  methods: {
    configWebSocket() {
      this.$socket.on('connect', () => {
        console.log('WebSocket连接已建立')
      })
      this.$socket.on('disconnect', () => {
        this.$message.error('聊天室连接失败，请检查token或网络！');
      })
      this.$socket.on('connect_error', () => {
        this.$message.error('聊天室连接失败！');
      })
      this.$socket.on('message', (data) => {
        this.handleWebSocketMessage(data)
      })
    },

    handleWebSocketMessage(message) {
      console.log(message)
      switch (message.type) {
        case 'user_id':
          this.currentUserId = message.data
          break
        case 'rooms':
          this.rooms = message.data
          this.loadingRooms = false
          this.roomsLoadedCount = this.rooms.length
          break
        case 'messages':
          this.messages = message.data
          this.messagesLoaded = true
          break
        case 'newMessage':
          // eslint-disable-next-line no-case-declarations
          const newMessage = this.formatMessage(this.selectedRoom, message.data)
          this.messages.push(newMessage)
          break
        case 'roomLastMessage':
          // eslint-disable-next-line no-case-declarations
          const {roomId, lastMessage} = message.data
          // eslint-disable-next-line no-case-declarations
          const roomIndex = this.rooms.findIndex(r => r.roomId === roomId)
          if (roomIndex !== -1) {
            this.rooms[roomIndex].lastMessage = lastMessage
            this.rooms = [...this.rooms]
          }
          break
          // 其他消息类型的处理
      }
    },

    // async addCss() {
    //   if (import.meta.env.MODE === 'development') {
    //     const styles = await import('./../../src/styles/index.scss')
    //     const style = document.createElement('style')
    //     style.innerHTML = styles.default
    //     this.$refs.chatWindow.shadowRoot.appendChild(style)
    //   }
    // },

    resetRooms() {
      this.loadingRooms = true
      this.loadingLastMessageByRoom = 0
      this.roomsLoadedCount = 0
      this.rooms = []
      this.roomsLoaded = true
      this.startRooms = null
      this.endRooms = null
      this.resetMessages()
    },

    resetMessages() {
      this.messages = []
      this.messagesLoaded = false
      this.lastLoadedMessage = null
      this.previousLastLoadedMessage = null
    },

    fetchRooms() {
      this.resetRooms()
      this.fetchMoreRooms()
    },

    async fetchMoreRooms() {
      if (this.endRooms && !this.startRooms) {
        this.roomsLoaded = true
        return
      }
      // 通过WebSocket发送获取更多房间的请求
      this.$socket.emit('fetchMoreRooms', {
        currentUserId: this.currentUserId,
        roomsPerPage: this.roomsPerPage,
        startRooms: this.startRooms
      })
    },

    formatLastMessage(message, room) {
      if (!message.timestamp) return
      let content = message.content
      if (message.files?.length) {
        const file = message.files[0]
        content = `${file.name}.${file.extension || file.type}`
      }
      const username =
          message.sender_id !== this.currentUserId
              ? room.users.find(user => message.sender_id === user._id)?.username
              : ''
      return {
        ...message,
        ...{
          _id: message.id,
          content,
          senderId: message.sender_id,
          timestamp: formatTimestamp(
              new Date(message.timestamp.seconds * 1000),
              message.timestamp
          ),
          username: username,
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new:
              message.sender_id !== this.currentUserId &&
              (!message.seen || !message.seen[this.currentUserId])
        }
      }
    },

    fetchMessages({room, options = {}}) {
      this.$emit('show-demo-options', false)
      if (options.reset) {
        this.resetMessages()
      }
      if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
        this.messagesLoaded = true
        return
      }
      this.selectedRoom = room.roomId
      // 通过WebSocket发送获取消息的请求
      this.$socket.emit('fetchMessages', {
        roomId: room.roomId,
        messagesPerPage: this.messagesPerPage,
        lastLoadedMessage: this.lastLoadedMessage
      })
    },

    markMessagesSeen(room, message) {
      if (
          message.sender_id !== this.currentUserId &&
          (!message.seen || !message.seen[this.currentUserId])
      ) {
        // 通过WebSocket发送标记消息已读的请求
        this.$socket.emit('markMessageSeen', {
          roomId: room.roomId,
          messageId: message.id,
          userId: this.currentUserId
        })
      }
    },

    formatMessage(room, message) {
      const formattedMessage = {
        ...message,
        ...{
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, 'HH:mm'),
          date: parseTimestamp(message.timestamp, 'DD MMMM YYYY'),
          username: room.users.find(user => message.sender_id === user._id)
              ?.username,
          distributed: true
        }
      }
      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.sender_id
          }
        }
      }
      return formattedMessage
    },

    async sendMessage({content, roomId, files, replyMessage}) {
      const message = {
        sender_id: this.currentUserId,
        content,
        timestamp: new Date()
      }
      if (files) {
        message.files = this.formattedFiles(files)
      }
      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        }
        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }
      this.messages.push(message)
      // 通过WebSocket发送发送消息的请求
      this.$socket.emit('sendMessage', {
        message,
        roomId
      })
    },

    async editMessage({messageId, newContent, roomId, files}) {
      const newMessage = {edited: new Date()}
      newMessage.content = newContent
      if (files) {
        newMessage.files = this.formattedFiles(files)
      } else {
        newMessage.files = null
      }
      // 通过WebSocket发送编辑消息的请求
      this.$socket.emit('editMessage', {
        newMessage,
        roomId,
        messageId
      })
    },

    async deleteMessage({message, roomId}) {
      // 通过WebSocket发送删除消息的请求
      this.$socket.emit('deleteMessage', {
        roomId,
        messageId: message._id
      })
    },

    async uploadFile({file, messageId, roomId}) {
      // 处理文件上传逻辑（可根据实际需求调整）
      console.log('Uploading file:', file, messageId, roomId)
    },

    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(message => message._id === messageId)
      if (!message || !message.files) return
      message.files.find(file => file.url === fileUrl).progress = progress
      this.messages = [...this.messages]
    },

    formattedFiles(files) {
      const formattedFiles = []
      files.forEach(file => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl
        }
        if (file.audio) {
          messageFile.audio = true
          messageFile.duration = file.duration
        }
        formattedFiles.push(messageFile)
      })
      return formattedFiles
    },

    openFile({file}) {
      window.open(file.file.url, '_blank')
    },

    async openUserTag({user}) {
      let roomId
      this.rooms.forEach(room => {
        if (room.users.length === 2) {
          const userId1 = room.users[0]._id
          const userId2 = room.users[1]._id
          if (
              (userId1 === user._id || userId1 === this.currentUserId) &&
              (userId2 === user._id || userId2 === this.currentUserId)
          ) {
            roomId = room.roomId
          }
        }
      })
      if (roomId) {
        this.roomId = roomId
        return
      }
      // 通过WebSocket发送获取用户房间的请求
      this.$socket.emit('getUserRooms', {
        userId1: this.currentUserId,
        userId2: user._id
      })
    },

    async loadRoom(query) {
      query.forEach(async room => {
        if (this.loadingRooms) return
        // 通过WebSocket发送更新房间的请求
        this.$socket.emit('updateRoom', {
          roomId: room.id,
          lastUpdated: new Date()
        })
        this.roomId = room.id
        this.fetchRooms()
      })
    },

    menuActionHandler({action, roomId}) {
      switch (action.name) {
        case 'inviteUser':
          return this.inviteUser(roomId)
        case 'removeUser':
          return this.removeUser(roomId)
        case 'deleteRoom':
          return this.deleteRoom(roomId)
      }
    },

    messageSelectionActionHandler({action, messages, roomId}) {
      switch (action.name) {
        case 'deleteMessages':
          messages.forEach(message => {
            this.deleteMessage({message, roomId})
          })
      }
    },

    async sendMessageReaction({reaction, remove, messageId, roomId}) {
      // 通过WebSocket发送消息反应的请求
      this.$socket.emit('sendMessageReaction', {
        reaction,
        remove,
        messageId,
        roomId,
        userId: this.currentUserId
      })
    },

    typingMessage({message, roomId}) {
      if (roomId) {
        if (message?.length > 1) {
          this.typingMessageCache = message
          return
        }
        if (message?.length === 1 && this.typingMessageCache) {
          this.typingMessageCache = message
          return
        }
        this.typingMessageCache = message
        // 通过WebSocket发送用户正在输入的请求
        this.$socket.emit('updateRoomTypingUsers', {
          roomId,
          userId: this.currentUserId,
          action: message ? 'add' : 'remove'
        })
      }
    },

    addRoom() {
      this.resetForms()
      this.addNewRoom = true
    },

    async createRoom() {
      this.disableForm = true
      // 通过WebSocket发送创建房间的请求
      this.$socket.emit('createRoom', {
        username: this.addRoomUsername,
        currentUserId: this.currentUserId
      })
      this.addNewRoom = false
      this.addRoomUsername = ''
      this.fetchRooms()
    },

    inviteUser(roomId) {
      this.resetForms()
      this.inviteRoomId = roomId
    },

    async addRoomUser() {
      this.disableForm = true
      // 通过WebSocket发送邀请用户的请求
      this.$socket.emit('addRoomUser', {
        roomId: this.inviteRoomId,
        username: this.invitedUsername
      })
      this.inviteRoomId = null
      this.invitedUsername = ''
      this.fetchRooms()
    },

    removeUser(roomId) {
      this.resetForms()
      this.removeRoomId = roomId
      this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
    },

    async deleteRoomUser() {
      this.disableForm = true
      // 通过WebSocket发送移除用户的请求
      this.$socket.emit('deleteRoomUser', {
        roomId: this.removeRoomId,
        userId: this.removeUserId
      })
      this.removeRoomId = null
      this.removeUserId = ''
      this.fetchRooms()
    },

    async deleteRoom(roomId) {
      const room = this.rooms.find(r => r.roomId === roomId);
      if (
          room.users.find(user => user._id === 'SGmFnBZB4xxMv9V4CVlW') ||
          room.users.find(user => user._id === '6jMsIXUrBHBj7o2cRlau')
      ) {
        return alert('Nope, for demo purposes you cannot delete this room');
      }

      // 通过WebSocket发送删除房间的请求
      this.$socket.emit('deleteRoom', {
        roomId
      });

      this.fetchRooms();
    },

    resetForms() {
      this.disableForm = false
      this.addNewRoom = null
      this.addRoomUsername = ''
      this.inviteRoomId = null
      this.invitedUsername = ''
      this.removeRoomId = null
      this.removeUserId = ''
    },

    disconnectWebSocket() {
      if (this.$socket) {
        this.$socket.close();
        this.$socket = null;
      }
    }

    // ,incrementDbCounter(type, size) {
    // 	size = size || 1
    // 	this.dbRequestCount += size
    // 	console.log(type, size)
    // }
  }
}
</script>

<!--<style lang="scss" scoped>-->
<style scoped>
.window-container {
  width: 100%;
}

/*.window-mobile {*/

/*  form {*/
/*    padding: 0 10px 10px;*/
/*  }*/

/*}*/

form {
  padding-bottom: 20px;
}

/*input {*/
/*  padding: 5px;*/
/*  width: 140px;*/
/*  height: 21px;*/
/*  border-radius: 4px;*/
/*  border: 1px solid #d2d6da;*/
/*  outline: none;*/
/*  font-size: 14px;*/
/*  vertical-align: middle;*/

/*  &*/
/*  ::placeholder {*/
/*    color: #9ca6af;*/
/*  }*/

/*}*/

/*button {*/
/*  background: #1976d2;*/
/*  color: #fff;*/
/*  outline: none;*/
/*  cursor: pointer;*/
/*  border-radius: 4px;*/
/*  padding: 8px 12px;*/
/*  margin-left: 10px;*/
/*  border: none;*/
/*  font-size: 14px;*/
/*  transition: 0.3s;*/
/*  vertical-align: middle;*/

/*  &*/
/*  :hover {*/
/*    opacity: 0.8;*/
/*  }*/

/*  &*/
/*  :active {*/
/*    opacity: 0.6;*/
/*  }*/

/*  &*/
/*  :disabled {*/
/*    cursor: initial;*/
/*    background: #c6c9cc;*/
/*    opacity: 0.6;*/
/*  }*/

/*}*/

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>