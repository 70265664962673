<template>
  <div>
    <div
        class="app-container"
        :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
    >

      <chat-container
          :theme="theme"
          :is-device="isDevice"
          @show-demo-options="showDemoOptions = $event"
      />

    </div>
  </div>
</template>

<script>
import ChatContainer from './chat/ChatContainer'

export default {
  components: {
    ChatContainer
  },

  data() {
    return {
      theme: 'light',
      isDevice: false,
      showDemoOptions: true,
    }
  },

  mounted() {
    this.isDevice = window.innerWidth < 500
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.isDevice = window.innerWidth < 500
    })
  },
}
</script>

<!--<style lang="scss">-->
<style>
body {
  background: #fafafa;
  margin: 0;
}

.app-container {
  font-family: 'Quicksand', sans-serif;
  padding: 20px 30px 30px;
}

/*.app-mobile {*/
/*  padding: 0;*/

/*  &*/
/*  .app-mobile-dark {*/
/*    background: #131415;*/
/*  }*/

/*  .user-logged {*/
/*    margin: 10px 5px 0 10px;*/
/*  }*/

/*  select {*/
/*    margin: 10px 0;*/
/*  }*/

/*  .button-theme {*/
/*    margin: 10px 10px 0 0;*/

/*    .button-github {*/
/*      height: 23px;*/

/*      img {*/
/*        height: 23px;*/
/*      }*/

/*    }*/
/*  }*/
/*}*/

/*.user-logged {*/
/*  font-size: 12px;*/
/*  margin-right: 5px;*/
/*  margin-top: 10px;*/

/*  &*/
/*  .user-logged-dark {*/
/*    color: #fff;*/
/*  }*/

/*}*/

</style>