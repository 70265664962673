import Vue from 'vue'
import App from './App.vue'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import VueHotkey from 'v-hotkey'
import io from 'socket.io-client';

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

//引入插件
import VueRounter from 'vue-router';
//引入路由配置
import router from './router/index.js'	//因为是index文件，所以也可以不加
//引入element-ui完成的组件库
import ElementUI from 'element-ui';
//引入element-ui（全部）样式
import 'element-ui/lib/theme-chalk/index.css';
//应用element-ui（因为element-ui是vue的一个插件）


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueMarkdownEditor);
//使用插件
Vue.component(VueRounter);
Vue.use(VueHotkey)


// 创建 WebSocket 连接并设置为全局属性
const token = localStorage.getItem('token');
if (token && typeof Vue.prototype.$socket === "undefined") {
    Vue.prototype.$socket = io(process.env.VUE_APP_WS_URL, {
        query: {token},
        // transports: ['websocket'], // 强制仅使用 WebSocket
        // upgrade: false // 禁止尝试升级（因为已经是首选传输）
    });
}

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
